<template>
	<div>
		<div class="out">
			<div class="header">
				<div class="logo Title">

					<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
							@click="$router.back()" />{{ $t('main.holdertran') }}</div>
				</div>
				<div class="right-icon">
					<img src="../assets/img/logo-icon.png" alt="">
					<div class="cc-wc">
						{{ $t('main.titleCenter') }}
					</div>
				</div>
			</div>
			<div class="content">
				<div class="zb-top">
					<img :src="pictures.length ? pictures[0] + '?width=350' : ''" alt="">
					<div class="info">
						<div class="">
							<span>{{ $t('main.trname') }}：</span>
							<span style="color:#0056d7">{{ name }}</span>
						</div>
						<div>
							<span>{{ $t('main.gwtc') }}</span>
							<span style="color:#fe0000">{{ serial_number }}</span>
						</div>
						<div>
							<span>{{ $t('main.trlevel') }}</span>
							<span>{{ level }}</span>
						</div>
					</div>
				</div>
				<div class="zb-edit">
					<!-- <div class="cert2pay" v-show="!certificate">
						<div class="payNum">
							<div><span style="font-size:0.15rem;">￥</span> {{ level_pay }}</div>
							<div>证书存证费</div>
						</div>
						<div class="choosePay">
							<div>费用支付对象：</div>
							<div>
								<div @click="changePay(0)">
									<div :class="pay_index === 0 ? 'active' : ''"></div>
									<div>本人</div>
								</div>
								<div @click="changePay(1)">
									<div :class="pay_index === 1 ? 'active' : ''"></div>
									<div>对方</div>
								</div>
							</div>
						</div>
					</div> -->

					<div class="zb-pic zb-edit-item">


						<div class="bg_text" style="margin-top: 0.2rem">{{$t('main.transferP')}}</div>


						<div class="newStep">
							<div class="left">
								<div class="radius"></div>
								<div class="line"></div>
							</div>
							<div class="right">
								<div class="r_title">01</div>
								<!-- <div>请认证持有者用微信扫一扫扫描以下二维码</div> -->
								<div>{{ $t('main.aystep') }}</div>
								
							</div>
						</div>
						<div class="newStep">
							<div class="left">
								<div class="radius"></div>
								<div class="line"></div>
							</div>
							<div class="right">
								<div class="r_title">02</div>
								<!-- <div>{{ pay_index == 0 ?
									'新任持有者收到提示；提交持有者认证信息；' : pay_index == 1 ? `新任持有者收到提示；支付证书存证费用￥${level_pay}，提交持有者认证信息` :
										'新任持有者收到提示；提交持有者认证信息'
								}}</div> -->
								<div>{{ $t('main.allstep2') }}</div>
								<div class="qr-code" id="qrcode"></div>
							</div>
						</div>
						<div class="newStep">
							<div class="left">
								<div class="radius"></div>
								<div class="line"></div>
							</div>
							<div class="right">
								<div class="r_title">03</div>
								<!-- <div>
									{{ pay_index == 0 ? `您确认新任持有者身份；由您支付证书存证费用￥${level_pay}，同意转让认证持有权与新任持有者，完成认证` :
									pay_index
										== 1 ? '您确认新任持有者身份；同意转让认证持有权与新任持有者，完成认证。' : '您确认新任持有者身份；同意转让认证持有权与新任持有者，完成认证。' }}
								</div> -->
								<div>{{ $t('main.aystep3') }}</div>
							</div>
						</div>

						<!-- <div class="step step1">
							<img class="top-text" src="../assets/img/step-icon1.png" alt="">
							<div class="mark-flex">
								<div class="mark-out">
									<div class="mark-in">

									</div>
								</div>{{ $t('main.arstep') }}
							</div>

						</div>
						<div class="line-x line-x1">

						</div>
						<div class="step step2">
							<img class="top-text" src="../assets/img/step-icon2.png" alt="">
							<div class="mark-flex">
								<div class="mark-out">
									<div class="mark-in">

									</div>
								</div>{{ $t('main.allstep2') }}
							</div>

							<div class="qr-code" id="qrcode">

							</div>
							<div class="qr-tips">
								{{ $t('main.qrcode30') }}
							</div>
							<div class="tip_btn" @click="getData">
								<div v-show="isReturn"> <img src="../assets/img/tips.png" alt=""></div>
								<div>{{ $t('main.cerinfo') }}</div>
							</div>
						</div>
						<div class="line-x line-x2">

						</div>
						<div class="step step3">
							<img class="top-text" src="../assets/img/step-icon3.png" alt="">
							<div class="mark-flex flex_start">
								<div class="mark-out">
									<div class="mark-in">

									</div>
								</div>
								<div class="mark-text">{{ $t('main.aystep3') }}</div>
							</div>

						</div> -->
						<div class="sq-tips-text">
							{{ $t('main.noteRZ') }}
						</div>
						<div class="view_btn" @click="getData">
							<div v-show="isReturn"> <img src="../assets/img/tips.png" alt=""></div>
							{{ $t('main.cerinfo') }}
						</div>
					</div>


				</div>
			</div>
		</div>
		<!--  -->
		<div class="pop pop-cy-rz" v-show="info" @click="closeQr">
			<div class="pop_zhuanyi" @click.stop>
				<div class="pop_titile">

					{{ $t('main.holdertran') }}

				</div>
				<div class="pop_avatar">
					<img :src="pop.avatar" alt="" />
				</div>
				<div class="pop_wx_name">
					{{ pop.username }}<span>{{ $t('main.syatca') }}</span>
				</div>
				<diV class="pop_main">
					<div>{{ $t('main.certrinfo') }}</div>
					<div>{{ $t('main.trname') }}：
						<span>{{ name }}</span>
					</div>
					<div>{{ $t('main.gwtc') }}
						{{ serial_number }}
					</div>
					<div>
						{{ $t('main.cerdisplay') }}
						{{ pop.name == pop.username ? $t('main.wxnickname') : pop.name }}
					</div>
				</diV>
				<!-- <div class="pop_center" v-show="!certificate">
					<div>
						￥{{ levelList.find(item => item.name == level) ? levelList.find(item => item.name == level).auth_fee
							:
							'0.00' }}
					</div>
					<div>由{{ pay_index == 0 ? '本人' : '对方' }}支付证书存证费用</div>
					<div>是否确定通过认证于：<span>{{ pop.username }}</span></div>
				</div> -->
				<div class="view_btn" @click="verify(1)" style="margin-bottom:0.1rem">{{$t('main.confirmTran')}}</div>
				<div class="" @click="verify(2)">{{ $t('main.cancel') }}</div>
				<!-- <div class="pop_body">
					<div class="wx_info">
						
						<div class="wx_info_name">
							<div class="">{{ $t('main.wxnickname') }}：<span style="color:#4ab006"></span>
							</div>
							<div>{{ $t('main.syatca') }} </div>
						</div>
					</div>
					<div class="list_item">
						{{ $t('main.certrinfo') }}
					</div>
					<div class="list_item">
						<span>{{ $t('main.trname') }}：</span>
						<span style="color:#0056d7">{{ name }}</span>
					</div>
					<div class="list_item">
						<span>{{ $t('main.gwtc') }}</span>
						<span style="color:#fe0000">{{ serial_number }}</span>
					</div>
					<div class="list_item">
						<span>{{ $t('main.cerdisplay') }}</span>
						<span>{{ pop.name == pop.username ? $t('main.wxnickname') : pop.name }}</span>
					</div>
					<div class="list_item">
						{{ $t('main.msypc') }}<span style="color:#4ab006">{{ pop.username }}</span>
					</div>

				</div>
				<div class="button_list">
					<div @click="verify(2)">{{ $t('main.cancel') }}</div>
					<div @click="verify(1)">{{ $t('main.determine') }}</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
	data() {
		return {
			info: false,
			isInfo: false,
			qrcode: '',
			pop: {
				avatar: '',
				username: '',
				name: ''
			},
			name: '',
			serial_number: '',
			isReturn: false,
			ar_id: '',
			pictures: [],
			level: "",
			certificate: "",
			levelList: [],
			pay_index: 1,
			level_pay: 0
		}
	},
	mounted() {
		this.name = this.$route.query.name
		this.serial_number = this.$route.query.serial_number
		this.init()

	},
	destroyed() {
		clearInterval(this.timer)
	},
	methods: {
		async init() {
			await this.axios.get('/goods/level').then((res) => {

				let level_list = res.data.data.list
				let list = []
				for (let i = 0; i < level_list.length; i++) {
					if (this.$i18n.locale == 'en') {
						if (level_list[i].name_intl) {
							level_list[i].name = level_list[i].name_intl
						}
					}
					list.push(level_list[i])
				}
				this.levelList = list
			})
			let newdata = ''
				await this.axios.post('common/encrypt',{goods_id: this.$route.params.id}).then((res)=>{
					newdata = res.data.data.data_encrypted
				})
			await this.axios.get('/goods/info?data=' + newdata).then((res) => {
				this.certificate = res.data.data.certificate
				// if (this.certificate) {
				// 	this.pay_index = 2
				// }
				this.pictures = res.data.data.pictures
				this.level = this.$i18n.locate == 'en' ? res.data.data.level.name_intl : res.data.data.level.name
				this.level_pay = this.levelList.find(item => item.name == this.level) ? this.levelList.find(item => item.name == this.level).auth_fee : '0.00'
			})
			// + '&ivType=' + this.pay_index
			await this.axios.get('/collection/invite?goods_id=' + this.$route.params.id ).then((res) => {

				this.qrcode = res.data.data.qrCode
				this.isInfo = !!res.data.data.applyRecord
				if (res.data.data.applyRecord) {
					this.pop.username = res.data.data.applyRecord.user.username
					this.pop.avatar = res.data.data.applyRecord.user.avatar
					this.pop.name = res.data.data.applyRecord.name
					this.isReturn = true
					this.ar_id = res.data.data.applyRecord.id
				} else {
					this.timer = setInterval(() => {
						this.axios.get('/collection/invite?goods_id=' + this.$route.params.id).then((res) => {
							if (res.data.data.applyRecord) {
								this.pop.username = res.data.data.applyRecord.user.username
								this.pop.avatar = res.data.data.applyRecord.user.avatar
								this.pop.name = res.data.data.applyRecord.name
								this.isReturn = true
								this.ar_id = res.data.data.applyRecord.id
								clearInterval(this.timer)
							}
						})
					}, 5000)
				}
			})

			if (!this.qrcode) {
				// weui.alert('该藏品已认证，请勿重复认证')
				return
			}
			// + '&pay=' + this.pay_index
			let url = location.protocol + '//' + location.host
			this.qqr = new QRCode(document.getElementById('qrcode'), {
				text: url + '/api/auth/login?url=' + encodeURIComponent(url + '/zyinfo?qrCode=' + this.qrcode), //要生成二维码的网址
				width: 256, //图像宽度
				height: 256, //图像高度
				colorDark: '#000000', //前景色
				colorLight: '#ffffff', //背景色
				margin: 0, //外边距
				correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
			})


		},
		async changePay(e) {
			this.pay_index = e
			document.getElementById('qrcode').innerHTML = ""
			await this.axios.get('/collection/invite?goods_id=' + this.$route.params.id + '&ivType=' + this.pay_index).then((res) => {
				this.qrcode = res.data.data.qrCode
			})
			if (this.qrcode) {
				let url = location.protocol + '//' + location.host
				new QRCode(document.getElementById('qrcode'), {
					text: url + '/api/auth/login?url=' + encodeURIComponent(url + '/zyinfo?qrCode=' + this.qrcode + '&pay=' + this.pay_index), //要生成二维码的网址
					width: 256, //图像宽度
					height: 256, //图像高度
					colorDark: '#000000', //前景色
					colorLight: '#ffffff', //背景色
					margin: 0, //外边距
					correctLevel: QRCode.CorrectLevel.L //容错级别。属性值有：QRCode.CorrectLevel.L、QRCode.CorrectLevel.M、QRCode.CorrectLevel.Q、QRCode.CorrectLevel.H
				})
			}
		},
		closeQr() {
			this.info = false
		},
		getData() {
			if (this.isReturn) {
				this.info = true
				return
			}
			this.axios.get('/collection/invite?goods_id=' + this.$route.params.id).then((res) => {
				if (res.data.data.applyRecord) {
					this.pop.username = res.data.data.applyRecord.user.username
					this.pop.avatar = res.data.data.applyRecord.user.avatar
					this.pop.name = res.data.data.applyRecord.name
					this.info = true
					this.isReturn = true
					this.ar_id = res.data.data.applyRecord.id
				} else {
					weui.alert(this.$t('main.holderNsu'), { buttons: [{ label: this.$t('main.confIrm') }] })
				}
			})
		},
		async verify(status) {
			this.axios.post('/collection/verify', { id: this.ar_id, status: status }).then((res) => {
					if (res.data.code == 0) {
						if (status == 1) {
							this.info = false
							weui.alert(this.$t('main.cersucc'), {
								buttons: [{
									label: this.$t('main.confIrm'), onClick: () => {
										this.$router.push('/guanli')
									}
								}]
							})
						} else {
							this.info = false
							weui.alert(this.$t('main.authcancel'), {
								buttons: [{
									label: this.$t('main.confIrm'), onClick: () => {
										this.$router.push('/guanli')
									}
								}]
							})
						}
					} else {
						weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
					}


				})
			// if (status == 1 && this.pay_index === 0) {
			// 	await this.axios.post('/collection/payTransApply', { goods_id: this.$route.params.id }).then((res) => {
			// 		let wxpay = res.data.data
			// 		WeixinJSBridge.invoke(
			// 			'getBrandWCPayRequest', {
			// 			appId: wxpay.appId,
			// 			timeStamp: wxpay.timestamp,
			// 			nonceStr: wxpay.nonceStr, // 支付签名随机串，不长于 32 位
			// 			package: wxpay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
			// 			signType: wxpay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
			// 			paySign: wxpay.paySign,

			// 		}, (res) => {
			// 			if (res.err_msg == "get_brand_wcpay_request:ok") {
			// 				weui.alert(this.$t('main.paysucc'),
			// 					{
			// 						buttons: [{
			// 							label: this.$t('main.confIrm'), onClick: () => {
			// 								this.axios.post('/collection/verify', { id: this.ar_id, status: status }).then((res) => {
			// 									if (res.data.code == 0) {
			// 										if (status == 1) {
			// 											this.info = false
			// 											setTimeout(() => {
			// 												weui.alert(this.$t('main.cersucc'), {
			// 													buttons: [{
			// 														label: this.$t('main.confIrm'), onClick: () => {
			// 															this.$router.push('/guanli')
			// 														}
			// 													}]
			// 												})
			// 											}, 500)

			// 										} else {
			// 											this.info = false
			// 											setTimeout(() => {
			// 												weui.alert(this.$t('main.authcancel'), {
			// 													buttons: [{
			// 														label: this.$t('main.confIrm'), onClick: () => {
			// 															this.$router.push('/guanli')
			// 														}
			// 													}]
			// 												})
			// 											}, 500)
			// 										}
			// 									} else {
			// 										weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
			// 									}


			// 								})
			// 							}
			// 						}]
			// 					}
			// 				)
			// 			} else {
			// 				weui.alert(this.$t('main.payfail'), { buttons: [{ label: this.$t('main.confIrm') }] })
			// 				return
			// 			}
			// 		})
			// 	})
			// } else {
			// 	this.axios.post('/collection/verify', { id: this.ar_id, status: status }).then((res) => {
			// 		if (res.data.code == 0) {
			// 			if (status == 1) {
			// 				this.info = false
			// 				weui.alert(this.$t('main.cersucc'), {
			// 					buttons: [{
			// 						label: this.$t('main.confIrm'), onClick: () => {
			// 							this.$router.push('/guanli')
			// 						}
			// 					}]
			// 				})
			// 			} else {
			// 				this.info = false
			// 				weui.alert(this.$t('main.authcancel'), {
			// 					buttons: [{
			// 						label: this.$t('main.confIrm'), onClick: () => {
			// 							this.$router.push('/guanli')
			// 						}
			// 					}]
			// 				})
			// 			}
			// 		} else {
			// 			weui.alert(res.data.msg, { buttons: [{ label: this.$t('main.confIrm') }] })
			// 		}


			// 	})
			// }

		}
	}
}
</script>

<style scoped>
.out {
	/* padding: 0rem 0.15rem; */
	min-height: 100vh;
	box-sizing: border-box;
	/* background: url(../assets/img/index_bg_fn.png) no-repeat center; */
	background-size: cover;
	font-size: 0.15rem;
	background: #f6d596;
}

.header {
	display: flex;
	justify-content: space-between;
	/* line-height: 0; */
	margin-bottom: 0rem;
	margin-bottom: 0rem;
	/* margin: 0 -0.15rem; */
	padding: 0 0.2rem;
	align-items: center;
	margin-bottom: 0;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #FFFFFF;
}

.header_noshadow {
	box-shadow: none;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
	white-space: nowrap;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}

.zb-edit {
	font-size: 0.13rem;
	background: url(../assets/img/zy_b_bg.png) no-repeat;
	border-radius: 0.08rem 0.08rem 0 0;
	background-size: auto 100%;
	background-attachment: fixed;
	padding: 0.15rem;
	box-sizing: border-box;
}

.cert2pay {
	background: url(../assets/img/chooseP.png) no-repeat;
	background-size: 100% 100%;
	width: 3.45rem;
	height: 0.96rem;
	display: flex;
	align-items: center;
}

.cert2pay .payNum {
	flex-shrink: 0;
	box-sizing: border-box;
	padding: 0 0.16rem;
	color: #fff;
	width: 1.45rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-right: dashed 1px #ffebcc;
}

.cert2pay .payNum>div:nth-child(1) {
	color: #ffebcc;
	font-size: 0.21rem;

}

.cert2pay .choosePay {
	flex: 1;
	flex-shrink: 0;
	padding: 0 0.16rem;
	padding-left: 0.25rem;
}

.choosePay>div:nth-child(1) {
	font-size: 0.12rem;
	color: #fff;
}

.choosePay>div:nth-child(2) {
	font-size: 0.15rem;
	color: #ffebcc;
	display: flex;
	justify-content: space-between;
	margin-top: 0.15rem;
}

.choosePay>div:nth-child(2)>div {
	display: flex;

}

.choosePay>div:nth-child(2)>div>div:nth-child(1) {
	width: 0.2rem;
	height: 0.2rem;
	background: #c9aba3;
	border-radius: 0.03rem;
	margin-right: 0.12rem;
}

.choosePay>div:nth-child(2)>div>div:nth-child(1).active {
	background: url(../assets/img/zy_gou.png) no-repeat #faf0e0;
	background-size: 0.15rem 0.12rem;
	background-position: center center;
}

.zb-top {
	width: 100%;
	height: 1.45rem;
	background: url(../assets/img/zhuanranF.png) no-repeat;
	background-size: 100% auto;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 0 0.15rem;
}

.zb-top img {
	width: 0.94rem;
	height: 0.94rem;
	margin-right: 0.2rem;
}

.zb-top .info {
	display: flex;
	flex-direction: column;
	height: 0.94rem;
	justify-content: space-between;
}

.zb-edit-item {
	margin: 0;
	padding: 0;
	/* background: #FFFFFF; */
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	/* margin-bottom: 0.17rem; */
	padding-top: 0;
	position: relative;
}

.zb-edit-item-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #9E9E9E;
	margin-bottom: 0.21rem;
}

.step {
	color: #343434;
	font-size: 0.15rem;
	margin-bottom: 0.22rem;
	display: flex;
	flex-direction: column;
}

.step3 {
	margin-bottom: 0.1rem;
}

.body {
	padding: 0.15rem;
}

.list_item {
	color: #343434;
	font-size: 0.15rem;
	margin-bottom: 0.14rem;
}

.qr-code {
	margin-top: 0.11rem;
	text-align: center;
	display: flex;
	justify-content: center;
	width: 1.76rem;
	height: 1.76rem;
	align-items: center;
	background: #fff;
	margin-left: 0.59rem;
}

.qr-tips {
	text-align: center;
	display: flex;
	justify-content: center;
	color: #9E9E9E;
	font-size: 0.14rem;
	margin-bottom: 0.14rem;
}

.tip_btn {
	background: #3662EC;
	border-radius: 6px;
	margin: 0 0.15rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	font-size: 0.14rem;
	padding-top: 0.12rem;
	padding-bottom: 0.12rem;
}

.tip_btn img {
	width: 0.2rem;
	height: 0.2rem;
	display: block;
	margin-right: 0.1rem;
}

.pop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	/* display: none; */
}

.pop_body {
	padding: 0.15rem;
	box-sizing: border-box;
}

.pop_zhuanyi {
	width: 3rem;

	background: url(../assets/img/zy_pop.png) no-repeat;
	background-size: 100% 100%;
	padding: 0.2rem 0.175rem;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 0.15rem;
	color: #fff;
}

.pop_titile {
	font-size: 0.15rem;
	color: #fff;
}

.pop_avatar {
	margin-top: 0.22rem;
	width: 0.84rem;
	height: 0.84rem;
	background: url(../assets/img/data_avatar_bg.png) no-repeat;
	background-size: 100% 100%;
	padding: 0.04rem;
	box-sizing: border-box;
}

.pop_avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.wx_info {
	display: flex;
	margin-bottom: 0.24rem;
}

.wx_info img {
	width: 1.02rem;
	height: 1.02rem;
	border-radius: 0.05rem;
	display: block;
}

.wx_info_name {
	height: 1.02rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 0.18rem;
	color: #343434;
	font-size: 0.15rem;
}

.button_list {
	display: flex;
	justify-content: space-between;
}

.button_list div {
	max-width: 45%;
	cursor: pointer;
	padding: 0.1rem 0.53rem;
	box-sizing: border-box;
	color: #FFFFFF;
	font-size: 0.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.1rem;

	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
}

.button_list div:nth-child(1) {
	background: #66676A;
}

.button_list div:nth-child(2) {
	background: #3662EC;
}

.title-ltfe {
	font-size: 0.18rem;
	color: #343434;
}

.title-right {
	font-size: 0.12rem;
}

.zb-edit-item-bottom input {
	height: 0.35rem;
	width: 100%;
	outline: none;
	margin-bottom: 0.15rem;
	border-radius: 6px;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
	border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
}

.sq-box {
	display: flex;
	align-items: center;
	margin-bottom: 0.26rem;
	color: #9E9E9E;
	font-size: 0.14rem;
	justify-content: space-between;
}

.sq-box .sq-btn {
	width: 1.16rem;
	height: 0.4rem;
	line-height: 0.4rem;
	text-align: center;
	border-radius: 0.1rem;
	background: #3662EC;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	color: #FFFFFF;
}

.sq-tips-text {
	display: flex;
	/* justify-content: flex-end; */
	align-items: center;
	color: #606060;
	font-size: 0.12rem;
	margin-bottom: 0;
	padding-left: 0.17rem;
	padding-right: 0.08rem;
}

.mark-text {
	width: 2.9rem;
}

.agree-box {
	display: flex;
	align-items: center;
}

.agree-box span {
	font-size: 0.14rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: #9E9E9E;
	margin-left: 0.1rem
}

.submint-info {
	width: 100%;
	height: 0.5rem;
	line-height: 0.5rem;
	text-align: center;
	color: #FFFFFF;
	border-radius: 5px;
	background: #2d71d7;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	/* margin-top:0.23rem; */
	margin-bottom: 0;
}

.top-text {
	width: 0.285rem;
	margin-bottom: -0.08rem;
	margin-left: 0.25rem;
}

.mark-flex {
	display: flex;
	align-items: center;
}

.mark-out {
	width: 0.14rem;
	height: 0.14rem;
	border: 1px #2d71d7 solid;
	border-radius: 50%;
	padding: 0.02rem;
	box-sizing: border-box;
	margin-right: 0.05rem;
}

.mark-in {
	width: 100%;
	height: 100%;
	background: #2d71d7;
	border-radius: 50%;
}

.line-x {
	border-left: 2px dashed #2d71d7;

	position: absolute;
	left: 0.06rem;
}

.line-x.line-x1 {
	height: 0.38rem;
	top: 1.27rem;
}

.line-x.line-x2 {
	height: calc(336px + 0.46rem);
	top: 1.87rem;
}

.flex_start {
	align-items: flex-start;
}

.flex_start .mark-out {
	margin-top: 0.06rem;
}

.nomore {
	overflow: hidden;

	text-overflow: ellipsis;

	-webkit-box-orient: vertical;

	display: -webkit-box;

	-webkit-line-clamp: 2;

	-webkit-box-flex: 2;
	height: 0.56rem;
}

.bg_text {
	width: 1.015rem;
	height: 0.295rem;
	background: url(../assets/img/bg_text.png) no-repeat;
	background-size: 100% 100%;
	color: #851616;
	font-size: 0.17rem;
	font-style: italic;
	font-weight: bold;
	box-sizing: border-box;
	padding-left: 0.25rem;
	padding-top: 0.05rem;
	white-space: nowrap;
}

.newStep {
	display: flex;
	margin-top: 0.08rem;
	font-size: 0.14rem;
}

.newStep:first-child {
	margin-top: 0;
}

.left {
	width: 0.17rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
}

.radius {
	margin-top: 0.08rem;
	flex-shrink: 0;
	width: 0.1rem;
	height: 0.1rem;
	border-radius: 50%;
	box-shadow: inset 0 0 0 1px #851616;
	margin-bottom: 0.09rem;
}

.line {
	flex: 1;
	flex-shrink: 0;
	border-right: solid 1px #ab817f;
}

.r_title {
	font-size: 0.18rem;
	color: #851616;
	font-style: italic;
	font-weight: bold;
	margin-bottom: 0.05rem;
}

:deep(#qrcode img) {
	width: 1.65rem;
	height: 1.65rem;
}

:deep(#qrcode canvas) {
	width: 1.65rem;
	height: 1.65rem;
}

.view_btn {
	position: relative;
	width: 2.28rem;
	height: 0.34rem;
	background: url(../assets/img/view-more.png) no-repeat;
	background-size: 100% 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.14rem;
	color: #333333;
	margin: 0.2rem auto;
}

.view_btn img {
	margin-top: 0.05rem;
	width: 0.2rem;
	height: 0.2rem;
}

.pop_wx_name {
	margin-top: 0.12rem;
	color: #feedcd;
	width: 100%;
	border-bottom: 1px dashed #e7bd74;
	text-align: center;
	padding-bottom: 0.135rem;
	box-sizing: border-box;
}

.pop_wx_name span {
	font-size: 0.12rem;
}

.pop_main {
	margin-top: 0.175rem;
	width: 100%;
	box-sizing: border-box;
	padding: 0 0.15rem;
	padding-bottom: 0.175rem;
	border-bottom: 1px dashed #e7bd74;
}

.pop_main>div>span {
	color: #feedcd;
}

.pop_center {
	margin-top: 0.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pop_center>div:nth-child(1) {
	font-size: 0.18rem;
	color: #feedcd;
}

.pop_center>div:nth-child(2) {
	font-size: 0.13rem;
}

.pop_center>div:nth-child(3)>span {
	color: #feedcd;
}
</style>